// Libs
import React from 'react'
import { Marker } from 'react-map-gl'
import PropTypes from 'prop-types'

// Assets
import BarPin from '../../assets/images/bar-pin.svg'
import CoffeePin from '../../assets/images/coffee-pin.svg'
import RestaurantPin from '../../assets/images/restaurant-pin.svg'
import FavoriteFlag from '../../assets/images/favorite.svg'

const MapMarkers = (props) => {
  const { places, selectedPlace, setSelectedPlace } = props
  return places
    ? places.map(p => (
        <Marker
          key={p.id}
          latitude={p.latitude}
          longitude={p.longitude}
          className={selectedPlace && selectedPlace.id === p.id ? 'selected' : ''}
        >
          <button
            className="pin"
            onClick={() => setSelectedPlace(p)}
          >
            {p.favorite ? (
              <img
                className="favorite-flag"
                src={FavoriteFlag}
                alt="Favorite"
              />
            ) : null}
            <img
              src={
                p.type.name === 'bar'
                  ? BarPin
                  : p.type.name === 'coffee'
                  ? CoffeePin
                  : RestaurantPin
              }
              alt="Pin"
            />
          </button>
        </Marker>
      ))
    : null
}

// Prop Types
MapMarkers.propTypes = {
  places: PropTypes.array,
  selectedPlace: PropTypes.object,
  setSelectedPlace: PropTypes.func.isRequired
}

export default MapMarkers
