// Libs
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Popup } from 'react-map-gl'
import PropTypes from 'prop-types'

// Store
import { removePlace } from '../../actions/placeActions'

// Components
import Icon from '../Icon'

class MapPopup extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false
    }
    this.getFavoriteButtonSelected = this.getFavoriteButtonSelected.bind(this)
    this.getFavoriteButtonStyle = this.getFavoriteButtonStyle.bind(this)
    this.getFullStarsListStyle = this.getFullStarsListStyle.bind(this)
    this.getPlaceInfosStyle = this.getPlaceInfosStyle.bind(this)
    this.removePlace = this.removePlace.bind(this)
  }
  getFavoriteButtonSelected() {
    return 'favorite-button' + (this.props.selectedPlace.favorite ? ' selected' : '')
  }
  getFavoriteButtonStyle() {
    return {
      backgroundColor: this.props.selectedPlace.favorite ? '#eb4d4b' : '#fff',
      color: this.props.selectedPlace.favorite ? '#fff' : '#eb4d4b'
    }
  }
  getFullStarsListStyle(avg) {
    return {
      width: Math.round(((100 / 5 * avg) * 10) / 10) + '%'
    }
  }
  getPlaceInfosStyle() {
    return {
      backgroundColor: this.props.selectedPlace.type.name === 'bar' ? '#786FA6' : this.props.selectedPlace.type.name === 'coffee' ? '#F19066' : '#C44569'
    }
  }
  removePlace() {
    this.setState({
      loading: true
    })
    this.props.removePlace(this.props.selectedPlace)
  }
  render() {
    if (!this.props.selectedPlace) {
      return (null)
    } else {
      let numberNotes = 0
      let totalNotes = Object.values(this.props.selectedPlace.placecriteria).reduce((t, { value }) => {
        let firstVal = parseInt(t)
        let secondVal = parseInt(value)
        if (!isNaN(firstVal) && !isNaN(secondVal)) {
          numberNotes++
        }
        return (!isNaN(firstVal) ? firstVal : 0) + (!isNaN(secondVal) ? secondVal : 0)
      }, 0)
      let avgNotes = numberNotes > 0 ? Math.round((totalNotes / numberNotes) * 10) / 10 : 0
      let starsList = []
      for (var i = 0; i < 5; i++) {
        starsList.push(<li key={i} className="star"></li>)
      }
      return (
        <Popup
          longitude={this.props.selectedPlace.longitude}
          latitude={this.props.selectedPlace.latitude}
          onClose={() => this.props.setSelectedPlace(null)}
          closeOnClick={false}
        >
          <button
            className={this.getFavoriteButtonSelected()}
            onClick={this.props.toggleFavoritePlace}
            style={this.getFavoriteButtonStyle()}
          >
            <Icon name="favorite" />
          </button>
          <button
            className="trash-button"
            onClick={() => this.removePlace()}
            disabled={this.props.loading}
          >
            <Icon name="trash" />
          </button>
          <div
            className="place-infos"
            onClick={() => this.props.showModalPlace(this.props.selectedPlace)}
            style={this.getPlaceInfosStyle()}
          >
            <div className="name">{this.props.selectedPlace.name}</div>
            <div className="stars-container">
              <ul className="empty-stars">{starsList}</ul>
              <ul
                className="full-stars"
                style={this.getFullStarsListStyle(avgNotes)}
              >
                {starsList}
              </ul>
            </div>
          </div>
        </Popup>
      )
    }
  }
}

// Prop Types
MapPopup.propTypes = {
  loading: PropTypes.bool.isRequired,
  removePlace: PropTypes.func.isRequired,
  selectedPlace: PropTypes.object,
  setSelectedPlace: PropTypes.func.isRequired,
  showModalPlace: PropTypes.func.isRequired,
  toggleFavoritePlace: PropTypes.func.isRequired,
}

// Map store actions to props
const mapDispatchToProps = {
  removePlace
}

export default connect(null, mapDispatchToProps)(MapPopup)
