// Libs
import React, { Component } from 'react'
import PropTypes from 'prop-types'

class LoadingScreen extends Component {
  render() {
    return this.props.loading ? (
      <div className="loader">
        <svg className="circular" viewBox="25 25 50 50">
          <circle className="path" cx="50" cy="50" r="20" fill="none" strokeWidth="2" strokeMiterlimit="10" />
        </svg>
      </div>
    ) : null
  }
}

// Prop Types
LoadingScreen.propTypes = {
  loading: PropTypes.bool.isRequired
}

export default LoadingScreen
