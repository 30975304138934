// Libs
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

// Store
import { removeErrors } from '../actions/userActions'

// Components
import Logo from '../components/Logo'
import RegisterForm from '../components/Auth/RegisterForm'

class Register extends Component {
  componentWillUnmount() {
    this.props.removeErrors()
  }
  render() {
    return (
      <div className="App RegisterPage">
        <Logo />
        <RegisterForm />
      </div>
    )
  }
}

// Prop Types
Register.propTypes = {
  removeErrors: PropTypes.func.isRequired
}

// Map store actions to props
const mapDispatchToProps = {
  removeErrors
}

export default connect(null, mapDispatchToProps)(Register)
