import { DELETE_USER_ERROR, LOGIN_ERROR, REGISTER_ERROR, UPDATE_USER, REMOVE_ALL_ERRORS } from '../actions'

const initialState = {
  currentUser: null,
  loginError: null,
  registerError: null,
  deleteUserError: null
}

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_USER:
      return {
        ...state,
        currentUser: action.payload,
        loginError: null,
        registerError: null
      }
    case LOGIN_ERROR:
      return {
        ...state,
        loginError: action.payload
      }
    case REGISTER_ERROR:
      return {
        ...state,
        registerError: action.payload
      }
    case DELETE_USER_ERROR:
      return {
        ...state,
        deleteUserError: null
      }
    case REMOVE_ALL_ERRORS:
      return {
        ...state,
        loginError: null,
        registerError: null,
        deleteUserError: null
      }
    default:
      return state
  }
}

export default userReducer