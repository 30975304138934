import { ADD_PLACE, EDIT_PLACE, FETCH_ALL_PLACES, REMOVE_PLACE, SET_CURRENT_POSITION, SET_FILTER, SET_SELECTED_PLACE, SWITCH_DISPLAY_MODE } from '../actions'

let displayMode = 'map'
if (localStorage.getItem('displayMode')) {
  displayMode = localStorage.getItem('displayMode')
} else {
  localStorage.setItem('displayMode', displayMode)
}

let filters = ['bar', 'restaurant', 'coffee']
if (localStorage.getItem('filters')) {
  filters = JSON.parse(localStorage.getItem('filters'))
} else {
  localStorage.setItem('filters', JSON.stringify(filters))
}

const initialState = {
  items: null,
  filters,
  displayMode,
  selectedPlace: null,
  currentPosition: []
}

const placeReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ALL_PLACES:
      return {
        ...state,
        items: action.payload
      }
    case ADD_PLACE:
      return {
        ...state,
        items: [...state.items, action.payload]
      }
    case SWITCH_DISPLAY_MODE:
      const displayMode = state.displayMode === 'map' ? 'list' : 'map'
      localStorage.setItem('displayMode', displayMode)
      return {
        ...state,
        displayMode
      }
    case EDIT_PLACE:
      return {
        ...state,
        items: [...state.items.filter(i => i.id !== action.payload.id), action.payload]
      }
    case REMOVE_PLACE:
      return {
        ...state,
        items: state.items.filter(i => i.id !== action.payload)
      }
    case SET_CURRENT_POSITION:
      return {
        ...state,
        currentPosition: action.payload
      }
    case SET_SELECTED_PLACE:
      return {
        ...state,
        selectedPlace: action.payload
      }
    case SET_FILTER:
      const filteredFilters = state.filters.filter(f => f !== action.payload.name)
      const filters = action.payload.value ? [...filteredFilters, action.payload.name] : filteredFilters
      return {
        ...state,
        filters
      }
    default:
      return state
  }
}

export default placeReducer