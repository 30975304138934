import axios from 'axios'

class Api {
  constructor() {
    this.apiURL = process.env.REACT_APP_API_URL
    this.getConfig = this.getConfig.bind(this)
  }
  getConfig() {
    const jwt = localStorage.getItem('jwt')
    return jwt ? {
      headers: {
        'Authorization': `Bearer ${jwt}`,
        'Accept-Language': 'en_US'
      }
    } : {
      headers: {
        'Accept-Language': 'en_US'
      }
    }
  }
  checkMe = () => {
    if (localStorage.getItem('jwt')) {
      return new Promise((resolve, reject) => axios
        .get(this.apiURL + '/users/me', this.getConfig())
        .then(res => {
          resolve(res.data)
        })
        .catch(error => {
          localStorage.removeItem('jwt')
          reject(error)
        })
      )
    } else {
      return new Promise((resolve, reject) => reject())
    }
  }
  deleteUser = (id, places) => {
    // TODO DELETE ALL PLACES + CRITERIAS OF THE USER
    if (localStorage.getItem('jwt')) {
      return new Promise((resolve, reject) => {
        Promise.all(
          // Delete place criteria
          places.map(p => p.placecriteria.map(pc => new Promise((resolve, reject) => {
            axios.delete(this.apiURL + '/placecriteria/' + pc.id, this.getConfig()).then(res => resolve(res))
          }))),
          // Delete place
          places.map(p => {
            return new Promise((resolve, reject) => {
              axios.delete(this.apiURL + '/places/' + p.id, this.getConfig()).then(res => resolve(res))
            })
          }),
          // Delete user
          new Promise((resolve, reject) => {
            axios.delete(this.apiURL + '/users/' + id, this.getConfig()).then(res => resolve(res))
          })
        ).then(res => {
          localStorage.removeItem('jwt')
          resolve(res.data)
        }).catch(error => {
          reject(error.response.data.message)
        })
      })
    } else {
      return new Promise((resolve, reject) => reject())
    }
  }
  login = (username, password) => {
    return new Promise((resolve, reject) => axios
      .post(this.apiURL + '/auth/local', {
        identifier: username,
        password: password,
      }, this.getConfig())
      .then(res => {
        // Handle success.
        localStorage.setItem('jwt', res.data.jwt)
        resolve(res.data)
      })
      .catch(error => {
        // Handle error.
        localStorage.removeItem('jwt')
        reject(error)
      })
    )
  }
  loginWithFacebook = (search) => {
    return new Promise((resolve, reject) => axios
      .get(this.apiURL + '/auth/facebook/callback' + search, this.getConfig())
      .then(res => {
        // Handle success.
        localStorage.setItem('jwt', res.data.jwt)
        resolve(res.data)
      })
      .catch(error => {
        // Handle error.
        localStorage.removeItem('jwt')
        reject(error)
      })
    )
  }
  fetchAllTypesPlaces = () => {
    return new Promise((resolve, reject) => axios.get(this.apiURL + '/typeplaces', this.getConfig())
      .then(res => {
        resolve(res.data)
      })
      .catch((e) => {
        // TODO error alert
        console.log(e)
      })
    )
  }
  fetchAllUserPlaces = () => {
    return new Promise((resolve, reject) => axios.get(this.apiURL + '/places', this.getConfig())
      .then(res => {
        let data = res.data.map(p => {
          const coords = p.coordinates.split(',')
          p.latitude = parseFloat(coords[0])
          p.longitude = parseFloat(coords[1])
          // axios.get('https://api.foursquare.com/v2/venues/explore?client_id=Z3JPHTFEZRQDHISRST0SNKZ5AQV3HVM5JYYSHRBRP50AIJYS&client_secret=LECPEQ1DX51MMFJKOGYTWJQOMQARL4A40IIUEYK0F3MMVZMK&v=20180323&limit=1&ll=' + parseFloat(coords[0]) + ',' + parseFloat(coords[1]) + '&query=' + p.name)
          //   .then(resFoursquare => console.log(resFoursquare))
          return p
        })
        resolve(data)
      })
      .catch((e) => {
        // TODO error alert
        console.log(e)
      })
    )
  }
  fetchAllCriteria = () => {
    return new Promise((resolve, reject) => axios.get(this.apiURL + '/criteria', this.getConfig())
      .then(res => {
        resolve(res.data)
      })
      .catch((e) => {
        // TODO error alert
        console.log(e)
      })
    )
  }
  placeToggleFavorite = (place) => {
    return new Promise((resolve, reject) => axios.put(this.apiURL + '/places/' + place.id, {
        favorite: !place.favorite
      }, this.getConfig())
        .then(res => {
          resolve(res.data)
        })
        .catch((e) => {
          // TODO error alert
          console.log(e)
        })
    )
  }
  register = (username, email, password) => {
    return new Promise((resolve, reject) => axios
      .post(this.apiURL + '/auth/local/register', {
        username,
        email,
        password,
      })
      .then(res => {
        localStorage.setItem('jwt', res.data.jwt)
        resolve(res.data)
      })
      .catch(error => {
        localStorage.removeItem('jwt')
        reject(error)
      })
    )
  }
  removePlace = (placeToRemove) => {
    return new Promise((resolve, reject) => {
      Promise.all(
        placeToRemove.placecriteria.map(pc => {
          return new Promise((resolve, reject) => {
            axios.delete(this.apiURL + '/placecriteria/' + pc.id, this.getConfig()).then(res => resolve(res))
          })
        })
      ).then(res => {
        // Then remove the place
        axios.delete(this.apiURL + '/places/' + placeToRemove.id, this.getConfig())
          .then(res => {
            resolve(placeToRemove.id)
          })
          .catch((e) => {
            // TODO error alert
            console.log(e)
          })
      })
    })
  }
}

export default Api