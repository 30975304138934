// Libs
import React, { Component } from 'react'
import { connect } from 'react-redux'
import ReactMapGL, { GeolocateControl, NavigationControl, FlyToInterpolator } from 'react-map-gl'
import Api from '../../services/Api'
import PropTypes from 'prop-types'

// Store
import { setSelectedPlace } from '../../actions/placeActions'

// Components
import LoadingScreen from '../LoadingScreen'
import MapMarkers from './MapMarkers'
import MapPopup from './MapPopup'

// Init api service
const apiService = new Api()

class MapContainer extends Component {
  _isMounted = false
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      viewport: {
        latitude: 0,
        longitude: 0,
        zoom: 14,
        bearing: 0,
        pitch: 0,
      }
    }
    this.filteredPlaces = this.filteredPlaces.bind(this)
    this.setMapPosition = this.setMapPosition.bind(this)
    this.setSelectedPlace = this.setSelectedPlace.bind(this)
    this.toggleFavoritePlace = this.toggleFavoritePlace.bind(this)
    this._onViewportChange = this._onViewportChange.bind(this)
  }
  filteredPlaces() {
    return this.props.places && this.props.placesFilters ? this.props.places.filter(p => this.props.placesFilters.indexOf(p.type.name) > -1) : []
  }
  setMapPosition(position) {
    this.setState({
      viewport: {
        bearing: 0,
        latitude: position[0],
        longitude: position[1],
        pitch: 0,
        zoom: 14
      }
    })
  }
  setSelectedPlace(place) {
    const viewport = this.state.viewport
    if (place) {
      viewport.zoom = 15
      viewport.longitude = place.longitude
      viewport.latitude = place.latitude + 0.002
      viewport.transitionDuration = 300
      viewport.transitionInterpolator = new FlyToInterpolator()
    }
    if (this._isMounted) {
      this.props.setSelectedPlace(place, this.setState({
        ...viewport
      }))
    }
  }
  toggleFavoritePlace() {
    if (this._isMounted) {
      this.setState({
        loading: true
      })
    }
    const selectedPlace = this.props.selectedPlace
    apiService.placeToggleFavorite(selectedPlace).then(res => {
      selectedPlace.favorite = !selectedPlace.favorite
      if (this._isMounted) {
        this.props.setSelectedPlace(selectedPlace, this.setState({
          loading: false
        }))
      }
    })
  }
  _onViewportChange(viewport) {
    viewport.viewState.zoom = 15
    if (this._isMounted) {
      this.setState({
        viewport: viewport.viewState
      })
    }
  }
  componentDidMount() {
    this._isMounted = true
    if (this.props.currentPosition) {
      this.setMapPosition(this.props.currentPosition)
    }
  }
  componentDidUpdate(prevProps) {
    const viewport = this.state.viewport
    // When a number of place changed and everything else loaded is added to our props
    if (this.props.selectedPlace && this.props.selectedPlace !== prevProps.selectedPlace && this._isMounted) {
      viewport.latitude = this.props.selectedPlace.latitude + 0.002
      viewport.longitude = this.props.selectedPlace.longitude
      viewport.zoom = 15
      this.setState({
        viewport
      })
    }
    if (this.props.places !== prevProps.places && this._isMounted) {
      const newPlace = (this.props.places && prevProps.places && this.props.places.length === prevProps.places.length + 1) ? this.props.places[this.props.places.length - 1] : null
      const deletedPlace = (this.props.places && prevProps.places && this.props.places.length === prevProps.places.length - 1)
      if (newPlace) {
        viewport.latitude = newPlace.latitude + 0.002
        viewport.longitude = newPlace.longitude
        viewport.zoom = 15
        this.props.setSelectedPlace(newPlace)
        this.setState({
          viewport
        })
      } else if (deletedPlace) {
        this.props.setSelectedPlace(null)
        viewport.zoom = 14
        this.setState({
          viewport
        })
      }
    }
    if (prevProps.currentPosition.length === 0 && this.props.currentPosition !== prevProps.currentPosition && this._isMounted) {
      this.setMapPosition(this.props.currentPosition)
    }
  }
  componentWillUnmount() {
    this._isMounted = false
  }
  render() {
    return (
      <div>
        <LoadingScreen
          loading={this.state.loading}
        />
        <ReactMapGL
          width="100vw"
          height="100vh"
          mapStyle="mapbox://styles/mapbox/streets-v11"
          mapboxApiAccessToken={process.env.REACT_APP_MAP_API_KEY}
          {...this.state.viewport}
          onViewportChange={(viewport) => this.setState({ viewport })}
        >
          <GeolocateControl
            positionOptions={{ enableHighAccuracy: true }}
            trackUserLocation={true}
            fitBoundsOptions={{ maxZoom: 10 }}
            onViewStateChange={this._onViewportChange}
          />
          <NavigationControl className="navigation-control" />
          <MapMarkers
            places={this.filteredPlaces()}
            selectedPlace={this.props.selectedPlace}
            setSelectedPlace={this.setSelectedPlace}
          />
          <MapPopup
            selectedPlace={this.props.selectedPlace}
            setSelectedPlace={this.setSelectedPlace}
            toggleFavoritePlace={this.toggleFavoritePlace}
            loading={this.state.loading}
            showModalPlace={(place) => this.props.showModalPlace(place)}
          />
        </ReactMapGL>
      </div>
    )
  }
}

// Prop Types
MapContainer.propTypes = {
  currentPosition: PropTypes.array.isRequired,
  places: PropTypes.array,
  placesFilters: PropTypes.array,
  selectedPlace: PropTypes.object,
  setSelectedPlace: PropTypes.func.isRequired,
  showModalPlace: PropTypes.func.isRequired
}

// Map store state to props
const mapStateToProps = (state) => ({
  places: state.placeReducer.items,
  placesFilters: state.placeReducer.filters,
  selectedPlace: state.placeReducer.selectedPlace,
  typesPlaces: state.typePlaceReducer.items
})

// Map store actions to props
const mapDispatchToProps = {
  setSelectedPlace
}

export default connect(mapStateToProps, mapDispatchToProps)(MapContainer)
