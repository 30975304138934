import { FETCH_ALL_CRITERIA } from '../actions'

const initialState = {
  items: null
}

const criteriaReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ALL_CRITERIA:
      return {
        ...state,
        items: action.payload
      }
    default:
      return state
  }
}

export default criteriaReducer