// Libs
import React, { Component } from 'react'
import { connect } from 'react-redux'

// Store
import store from '../store'
import { fetchAllCriteria } from '../actions/criteriaActions'
import { fetchAllUserPlaces, setSelectedPlace, setCurrentPosition } from '../actions/placeActions'
import { fetchAllTypesPlaces } from '../actions/typePlaceActions'

// Components
import LoadingScreen from '../components/LoadingScreen'
import MapContainer from '../components/Map/MapContainer'
import ModalPlace from '../components/Modals/ModalPlace'
import PlacesList from '../components/PlacesList'
import AppHeader from '../components/AppHeader'

class Map extends Component {
  _isMounted = false
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      loadingData: true,
      showModalPlace: null
    }
    this.closeModal = this.closeModal.bind(this)
    this.showModalPlace = this.showModalPlace.bind(this)
    this.setPosition = this.setPosition.bind(this)
  }
  closeModal() {
    if (this._isMounted) {
      this.setState({
        showModalPlace: null
      })
    }
  }
  showModalPlace(place) {
    if (this._isMounted) {
      this.setState({
        showModalPlace: place
      })
    }
  }
  setPosition(position) {
    if (this._isMounted) {
      this.props.setCurrentPosition([position.coords.latitude, position.coords.longitude])
      this.setState({
        loading: false
      })
    }
  }
  componentDidMount() {
    this._isMounted = true
    if (navigator.geolocation) {
      navigator.geolocation.watchPosition((position) => {
        this.setPosition(position)
      })
    }
    this.props.fetchAllCriteria()
    this.props.fetchAllUserPlaces(this.props.currentPosition)
    this.props.fetchAllTypesPlaces()
    this.unsubscribe = store.subscribe(() => {
      const storedFilters = localStorage.getItem('filters')
      if (this._isMounted && store.getState().criteriaReducer.items && store.getState().placeReducer.items && store.getState().typePlaceReducer.items) {
        this.setState({
          loadingData: false
        })
      }
      if (this._isMounted && storedFilters && store.getState().placeReducer.selectedPlace && JSON.stringify(store.getState().placeReducer.filters) !== storedFilters) {
        this.props.setSelectedPlace(null)
      }
      if (this._isMounted && storedFilters && JSON.stringify(store.getState().placeReducer.filters) !== storedFilters) {
        localStorage.setItem('filters', JSON.stringify(store.getState().placeReducer.filters))
      }
    })
  }
  componentWillUnmount() {
    this._isMounted = false
    this.unsubscribe()
  }
  render() {
    const placesList = this.props.displayMode === 'map' ? (
      <MapContainer currentPosition={this.props.currentPosition} showModalPlace={this.showModalPlace} />
    ) : (
      <PlacesList showModalPlace={this.showModalPlace} />
    )
    return (
      <div className="App">
        <LoadingScreen
          loading={(this.state.loading || this.state.loadingData)}
        />
        <AppHeader
          currentPosition={this.props.currentPosition}
          places={this.props.places}
          typesPlaces={this.props.typesPlaces}
          showModalPlace={this.showModalPlace}
        />
        {placesList}
        <ModalPlace
          place={this.state.showModalPlace}
          listCriteria={this.props.listCriteria}
          closeModal={this.closeModal}
        />
      </div>
    )
  }
}

// Map store state to props
const mapStateToProps = (state) => ({
  displayMode: state.placeReducer.displayMode,
  listCriteria: state.criteriaReducer.items,
  places: state.placeReducer.items,
  typesPlaces: state.typePlaceReducer.items,
  currentPosition: state.placeReducer.currentPosition
})

// Map store actions to props
const mapDispatchToProps = {
  fetchAllCriteria,
  fetchAllUserPlaces,
  fetchAllTypesPlaces,
  setCurrentPosition,
  setSelectedPlace
}

export default connect(mapStateToProps, mapDispatchToProps)(Map)
