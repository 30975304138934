// Libs
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'

// Store
import store from '../../store'
import { deleteUser, logout } from '../../actions/userActions'
import { resetPlaces } from '../../actions/placeActions'

// Components
import Copyrights from '../Copyrights'
import LoadingScreen from '../LoadingScreen'

// Assets
import AvatarSettingsImage from '../../assets/images/user-avatar-settings.svg'

class ModalPlace extends Component {
  constructor(props) {
    super(props)
    this.state = {
      listValues: [],
      loading: false,
      currentCriteriaId: 0
    }
    this.closeModal = this.closeModal.bind(this)
    this.deleteAccount = this.deleteAccount.bind(this)
    this.getModalStyle = this.getModalStyle.bind(this)
    this.logout = this.logout.bind(this)
  }
  closeModal() {
    this.props.closeModal()
  }
  deleteAccount() {
    const answer = window.confirm('Do you you really want to delete your account? This action will delete all your data. This can\'t be undone.')
    if (answer) {
      this.setState({
        loading: true
      })
      this.props.deleteUser(this.props.currentUser.id, this.props.places)
    }
  }
  getModalStyle() {
    return {
      transform: this.props.show ? 'translateY(0)' : 'translateY(100%)'
    }
  }
  logout() {
    this.setState({
      loading: true
    })
    this.props.resetPlaces()
    this.props.logout()
  }
  componentDidMount() {
    this.unsubscribe = store.subscribe(() => {
      if (!store.getState().userReducer.currentUser && !localStorage.getItem('jwt')) {
        this.setState({
          loading: false
        })
        this.props.history.push('/login')
      }
    })
  }
  componentWillUnmount() {
    this.unsubscribe()
  }
  render() {
    let userInfos = this.props.currentUser ? (
      <div className="user-infos">
        <p className="big-title">{this.props.currentUser.username}</p>
        <p>
          <strong className="d-block">Your e-mail:</strong>
          {this.props.currentUser.email}
        </p>
      </div>
    ) : null
    return (
      <div>
        <LoadingScreen
          loading={(this.state.loading)}
        />
        <div className="modal modalSettings" style={this.getModalStyle()}>
          <button className="close-modal-button" onClick={() => this.closeModal()}>x</button>
          <div className="content-modal">
            <img src={AvatarSettingsImage} alt="Avatar" className="avatar-settings" />
            <div className="flex-1">
              {userInfos}
            </div>
            <div className="actions">
              <button className="btn" onClick={this.logout}>Logout</button>
              <button className="no-style color-red" onClick={() => this.deleteAccount()}>Delete the account</button>
            </div>
            <p className="app-version">v.0.0.1-alpha.1</p>
            <Copyrights />
          </div>
        </div>
      </div>
    )
  }
}

// Prop Types
ModalPlace.propTypes = {
  closeModal: PropTypes.func.isRequired,
  currentUser: PropTypes.object,
  deleteUser: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  places: PropTypes.array,
  resetPlaces: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired
}

// Map store state to props
const mapStateToProps = (state) => ({
  currentUser: state.userReducer.currentUser,
  places: state.placeReducer.items
})

// Map store actions to props
const mapDispatchToProps = {
  deleteUser,
  logout,
  resetPlaces
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ModalPlace))
