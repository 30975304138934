// Criteria
export const FETCH_ALL_CRITERIA = 'FETCH_ALL_CRITERIA'

// Place
export const ADD_PLACE = 'ADD_PLACE'
export const EDIT_PLACE = 'EDIT_PLACE'
export const FETCH_ALL_PLACES = 'FETCH_ALL_PLACES'
export const REMOVE_PLACE = 'REMOVE_PLACE'
export const SET_FILTER = 'SET_FILTER'
export const SET_CURRENT_POSITION = 'SET_CURRENT_POSITION'
export const SET_SELECTED_PLACE = 'SET_SELECTED_PLACE'
export const SWITCH_DISPLAY_MODE = 'SWITCH_DISPLAY_MODE'

// Type Place
export const FETCH_ALL_TYPES_PLACES = 'FETCH_ALL_TYPES_PLACES'

// User
export const LOGIN_ERROR = 'LOGIN_ERROR'
export const REGISTER_ERROR =  'REGISTER_ERROR'
export const UPDATE_USER = 'UPDATE_USER'
export const DELETE_USER_ERROR = 'DELETE_USER_ERROR'
export const REMOVE_ALL_ERRORS = 'REMOVE_ALL_ERRORS'