// Libs
import React from 'react'
import PropTypes from 'prop-types'

// Components
import Icon from '../Icon'

const PlaceInfos = (props) => {
  let { address, direction, facebook, infos, instagram, isOpen, status, website } = props.infos
  const addressItem = address ? (
    <p className="address">{address}</p>
  ) : null
  const infosButton = infos ? (
    <li><a href={infos} className="btn squared" target="_blank" rel="noopener noreferrer"><Icon name="infos" /></a></li>
  ) : null
  const facebookButton = facebook ? (
    <li><a href={facebook} className="btn squared" target="_blank" rel="noopener noreferrer"><Icon name="facebook" /></a></li>
  ) : null
  const instagramButton = instagram ? (
    <li><a href={instagram} className="btn squared" target="_blank" rel="noopener noreferrer"><Icon name="instagram" /></a></li>
  ) : null
  const websiteButton = website ? (
    <li><a href={website} className="btn squared" target="_blank" rel="noopener noreferrer"><Icon name="web" /></a></li>
  ) : null
  const directionButton = direction ? (
    <li><a href={direction} className="btn squared" target="_blank" rel="noopener noreferrer"><Icon name="direction" /></a></li>
  ) : null
  const listInfosButtons = infos || facebook || instagram || website || direction ? (
    <ul className="list-infos-btn">
      {infosButton}
      {facebookButton}
      {instagramButton}
      {websiteButton}
      {directionButton}
    </ul>
  ) : null
  const openClosedClassName = 'open-closed ' + (isOpen ? 'color-green' : 'color-red')
  const openClosed = status ? (
    <p className={openClosedClassName}>{status}</p>
    ) : isOpen !== null ? (
    <p className={openClosedClassName}>{isOpen ? 'Open' : 'Closed'}</p>
  ) : null
  return (
    <div>
      {addressItem}
      {openClosed}
      {listInfosButtons}
    </div>
  )
}

// Prop Types
PlaceInfos.propTypes = {
  infos: PropTypes.object.isRequired
}

export default PlaceInfos
