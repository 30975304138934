import Api from '../services/Api'
import { DELETE_USER_ERROR, LOGIN_ERROR, REGISTER_ERROR, UPDATE_USER, REMOVE_ALL_ERRORS } from './index'

const apiService = new Api()

export const removeErrors = () => ({
  type: REMOVE_ALL_ERRORS,
  payload: null
})

export const checkMe = () => dispatch => {
  if (localStorage.getItem('jwt')) {
    apiService.checkMe()
      .then(res => dispatch({
        type: UPDATE_USER,
        payload: res
      }))
      .catch(err => {
        localStorage.removeItem('jwt')
        return dispatch({
          type: UPDATE_USER,
          payload: null
        })
      })
  } else {
    return dispatch({
      type: UPDATE_USER,
      payload: null
    })
  }
}

export const deleteUser = (id, places) => dispatch => {
  apiService.deleteUser(id, places)
    .then(res => dispatch({
      type: UPDATE_USER,
      payload: null
    }))
    .catch(err => dispatch({
      type: DELETE_USER_ERROR,
      payload: 'Une erreur s\'est produite!'
    }))
}

export const login = (username, password) => dispatch => {
  apiService.login(username, password)
    .then(res => dispatch({
      type: UPDATE_USER,
      payload: res.user
    }))
    .catch(err => {
      return dispatch({
        type: LOGIN_ERROR,
        payload: err.response.data && err.response.data.message ? err.response.data.message : err.response
      })
    })
}

export const loginWithFacebook = (search) => dispatch => {
  apiService.loginWithFacebook(search)
    .then(res => dispatch({
      type: UPDATE_USER,
      payload: res.user
    }))
    .catch(err => {
      return dispatch({
        type: LOGIN_ERROR,
        payload: err.response.data && err.response.data.message ? err.response.data.message : err.response
      })
    })
}

export const logout = () => dispatch => {
  localStorage.removeItem('jwt')
  return dispatch({
    type: UPDATE_USER,
    payload: null
  })
}

export const register = (username, email, password) => dispatch => {
  apiService.register(username, email, password)
    .then(res => dispatch({
      type: UPDATE_USER,
      payload: res.user
    }))
    .catch(err => dispatch({
      type: REGISTER_ERROR,
      payload: err.response.data && err.response.data.message ? err.response.data.message : err.response
    }))
}
