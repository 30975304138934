// Libs
import React, { Component } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import PropTypes from 'prop-types'

// Store
import { setSelectedPlace } from '../../actions/placeActions'

// Components
import ListSuggestions from '../ListSuggestions';

class Search extends Component {
  constructor(props) {
    super(props)
    this.state = {
      listSuggestions: [],
      inputValue: ''
    }
    this.addNewPlace = this.addNewPlace.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.resetValue = this.resetValue.bind(this)
  }
  today() {
    const today = new Date()
    let dd = today.getDate()
    let mm = today.getMonth() + 1
    const yyyy = today.getFullYear()
    if (dd < 10) {
      dd = '0' + dd
    }
    if (mm < 10) {
      mm = '0' + mm
    }
    return yyyy.toString() + mm.toString() + dd.toString()
  }
  handleChange(e) {
    this.setState({
      inputValue: e.target.value
    })
    if (e.target.value.length > 3) {
      const currentDate = this.today()
      const locationString = this.props.coords.length === 2 ? 'll=' + this.props.coords[0] + ',' + this.props.coords[1] : 'intent=global'
      const queryString = e.target.value
      axios.get('https://api.foursquare.com/v2/venues/search?' + locationString + '&client_id=' + process.env.REACT_APP_FS_PLACES_CLIENT + '&client_secret=' + process.env.REACT_APP_FS_PLACES_SECRET + '&v=' + currentDate + '&limit=10&query=' + queryString + '&categoryId=' + process.env.REACT_APP_FS_PLACES_BAR_CATID + ',' + process.env.REACT_APP_FS_PLACES_RESTAURANT_CATID)
        .then(res => {
          const venues = (res.data.response.venues.length > 0) ? res.data.response.venues.filter(v => v.location && v.location.city && v.location.country) : []
          if (venues.length > 0) {
            this.setState({
              listSuggestions: venues
            })
          } else if (locationString !== 'intent=global') {
            // If the request wasn't global, try to search everywhere in the world
            axios.get('https://api.foursquare.com/v2/venues/search?intent=global&client_id=' + process.env.REACT_APP_FS_PLACES_CLIENT + '&client_secret=' + process.env.REACT_APP_FS_PLACES_SECRET + '&v=' + currentDate + '&limit=10&query=' + queryString + '&categoryId=' + process.env.REACT_APP_FS_PLACES_BAR_CATID + ',' + process.env.REACT_APP_FS_PLACES_RESTAURANT_CATID)
              .then(res => {
                const venues = (res.data.response.venues.length > 0) ? res.data.response.venues.filter(v => v.location && v.location.city && v.location.country) : []
                if (venues.length > 0) {
                  this.setState({
                    listSuggestions: venues
                  })
                } else {
                  this.setState({
                    listSuggestions: 'No result found'
                  })
                }
              })
          } else {
            this.setState({
              listSuggestions: 'No result found'
            })
          }
        })
    } else if (e.target.value.length > 0) {
      this.setState({
        listSuggestions: 'Type at least 4 characters'
      })
    } else {
      this.setState({
        listSuggestions: []
      })
    }
  }
  resetValue() {
    this.setState({
      inputValue: '',
      listSuggestions: []
    })
  }
  addNewPlace(place) {
    const placeCategory = place.categories[0].name
    const listBars = ['Café des sports', 'Bar', 'Rade', 'Pub', 'Brasseur', 'nocturne', 'Club', 'Discothèque']
    const listCoffees = ['Café']
    const typePlace = (listBars.filter(b => placeCategory.indexOf(b) > -1)).length > 0 ? this.props.typesPlaces.filter(tp => tp.name === 'bar') : (listCoffees.filter(b => placeCategory.indexOf(b) > -1)).length > 0 ? this.props.typesPlaces.filter(tp => tp.name === 'coffee') : this.props.typesPlaces.filter(tp => tp.name === 'restaurant')
    const placeToAdd = {
      name: place.name,
      type: typePlace[0],
      coordinates: place.location.lat + ',' + place.location.lng,
      fsPlaceId: place.id
    }
    const placeFound = this.props.places.filter(p => p.fsPlaceId === place.id)
    if (placeFound.length > 0) {
      // Point to marker
      this.props.setSelectedPlace(placeFound[0])
      this.setState({ listSuggestions: [], inputValue: '' })
    } else {
      this.resetValue()
      this.props.showModalPlace(placeToAdd)
    }
  }
  render() {
    let resetSearchButton = this.state.inputValue ? (<button className="reset-search-button" onClick={() => this.resetValue()}>x</button>) : null
    let searchClass = 'search' + (this.state.inputValue ? ' with-value' : '')
    return (
      <div className={searchClass}>
        <input type="text" placeholder="Search a bar, a restaurant or a coffee shop..." value={this.state.inputValue} onChange={this.handleChange} />
        {resetSearchButton}
        <ListSuggestions listSuggestions={this.state.listSuggestions} addNewPlace={this.addNewPlace} />
      </div>
    )
  }
}

// Prop Types
Search.propTypes = {
  coords: PropTypes.array,
  places: PropTypes.array,
  setSelectedPlace: PropTypes.func.isRequired,
  showModalPlace: PropTypes.func.isRequired,
  typesPlaces: PropTypes.array,
}

// Map store actions to props
const mapDispatchToProps = {
  setSelectedPlace
}

export default connect(null, mapDispatchToProps)(Search)
