// Libs
import React, { Component } from 'react'
import store from '../../store'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

// Store
import { loginWithFacebook } from '../../actions/userActions'

// Components
import LoadingScreen from '../LoadingScreen'

class ConnectProvider extends Component {
  componentDidMount() {
    const { location: { search } } = this.props
    this.props.loginWithFacebook(search)
    this.unsubscribe = store.subscribe(() => {
      if (store.getState().userReducer.currentUser && localStorage.getItem('jwt')) {
        this.props.history.push('/')
      }
      if (store.getState().userReducer.loginError) {
        this.props.history.push('/login')
      }
    })
  }
  componentWillUnmount() {
    this.unsubscribe()
  }
  render() {
    return (<LoadingScreen loading={true} />)
  }
}

// Prop types
ConnectProvider.propTypes = {
  loginWithFacebook: PropTypes.func.isRequired
}

// Map store actions to props
const mapDispatchToProps = {
  loginWithFacebook
}

export default connect(null, mapDispatchToProps)(ConnectProvider)
