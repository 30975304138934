// Libs
import React from 'react'

// Assets
import LogoImage from '../../assets/images/logo.svg'

const Logo = () => {
  return (
    <img src={LogoImage} className="logo" alt="Logo" />
  );
}

export default Logo
