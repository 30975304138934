import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Switch, Route, withRouter } from 'react-router-dom'
import Login from './pages/Login'
import Register from './pages/Register'
import Map from './pages/Map'
import ConnectProvider from './components/Auth/ConnectProvider'
import PrivacyPolicy from './pages/PrivacyPolicy'
import { checkMe } from './actions/userActions'

const safePages = ["/login", "/register", "/privacy"];

class App extends Component {
  constructor(props) {
    super(props)
    this.checkUser = this.checkUser.bind(this)
  }
  checkUser() {
    if (localStorage.getItem('jwt')) {
      this.props.checkMe()
    } else if (safePages.indexOf(this.props.location.pathname) < 0 && this.props.location.pathname.indexOf('/connect/') < 0) {
      this.props.history.push('/login')
    }
  }
  componentDidMount = () => {
    this.checkUser()
  }
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged()
    }
  }
  onRouteChanged() {
    this.checkUser()
  }
  render() {
    return (
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/privacy" component={PrivacyPolicy} />
        <Route path="/register" component={Register} />
        <Route exact path="/connect/:provider" component={ConnectProvider} />
        <Route exact path="/" component={Map} />
      </Switch>
    )
  }
}

const mapDispatchToProps = {
  checkMe
}

export default connect(null, mapDispatchToProps)(withRouter(App))
