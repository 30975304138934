import Api from '../services/Api'
import { FETCH_ALL_TYPES_PLACES } from './index'

const apiService = new Api()

export const fetchAllTypesPlaces = () => dispatch => {
  apiService.fetchAllTypesPlaces()
    .then(res => dispatch({
      type: FETCH_ALL_TYPES_PLACES,
      payload: res
    }))
}
