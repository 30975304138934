import { FETCH_ALL_TYPES_PLACES } from '../actions'

const initialState = {
  items: null
}

const typePlaceReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ALL_TYPES_PLACES:
      return {
        ...state,
        items: action.payload
      }
    default:
      return state
  }
}

export default typePlaceReducer