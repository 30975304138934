import axios from 'axios'
import Api from '../services/Api'
import { ADD_PLACE, EDIT_PLACE, FETCH_ALL_PLACES, REMOVE_PLACE, SET_CURRENT_POSITION, SET_FILTER, SET_SELECTED_PLACE, SWITCH_DISPLAY_MODE } from './index'

const apiService = new Api()

export const switchDisplayMode = () => ({
  type: SWITCH_DISPLAY_MODE,
  payload: true
})

export const resetPlaces = () => ({
  type: FETCH_ALL_PLACES,
  payload: null
})

export const setCurrentPosition = (currentPosition) => ({
  type: SET_CURRENT_POSITION,
  payload: currentPosition
})

export const setSelectedPlace = (place) => ({
  type: SET_SELECTED_PLACE,
  payload: place
})

export const fetchAllUserPlaces = (currentPosition) => dispatch => {
  if (!localStorage.getItem('jwt')) {
    return {
      type: FETCH_ALL_PLACES,
      payload: null
    }
  }
  apiService.fetchAllUserPlaces()
    .then(res => {
      return dispatch({
        type: FETCH_ALL_PLACES,
        payload: res
      })
    })
}

export const addPlace = (place, listValues) => dispatch => {
  axios.post(process.env.REACT_APP_API_URL + '/places', place, apiService.getConfig())
    .then(res => {
      const place = res.data
      const newPlaceId = place.id
      Promise.all(
        listValues.map(v => {
          return new Promise((resolve, reject) => {
            axios.post(process.env.REACT_APP_API_URL + '/placecriteria', {
              value: v.value,
              criteria: v.idCriteria,
              place: newPlaceId
            }, apiService.getConfig()).then(res => resolve(res.data))
          })
        })
      ).then(res => {
        const coordinates = place.coordinates.split(',')
        place.id = newPlaceId
        place.longitude = parseFloat(coordinates[1])
        place.latitude = parseFloat(coordinates[0])
        place.placecriteria = res
        dispatch({
          type: ADD_PLACE,
          payload: place
        })
      })
    })
}

export const editPlace = (place, listValues) => dispatch => {
  Promise.all(
    listValues.map(v => {
      return new Promise((resolve, reject) => {
        const placeCriteria = place.placecriteria.filter(pc => (typeof pc.criteria === 'object' && pc.criteria !== null ? pc.criteria.id : pc.criteria) === v.idCriteria)
        if (placeCriteria.length === 1) {
          // If place criteria already exist, then update it
          axios.put(process.env.REACT_APP_API_URL + '/placecriteria/' + placeCriteria[0].id, {
            value: v.value
          }, apiService.getConfig()).then(res => resolve(res.data))
        } else {
          // Else if not, create it
          axios.post(process.env.REACT_APP_API_URL + '/placecriteria', {
            value: v.value,
            criteria: v.idCriteria,
            place: place.id
          }, apiService.getConfig()).then(res => resolve(res.data))
        }
      })
    })
  ).then(res => {
    // Update place with new values
    res.map(r => {
      place.placecriteria = place.placecriteria.filter(pc => (typeof pc.criteria === 'object' && pc.criteria !== null ? pc.criteria.id : pc.criteria) !== r.criteria.id)
      place.placecriteria.push(r)
      return r
    })
    dispatch({
      type: EDIT_PLACE,
      payload: place
    })
  })
}

export const removePlace = (place) => dispatch => {
  apiService.removePlace(place)
    .then(placeId => {
      dispatch({
        type: REMOVE_PLACE,
        payload: placeId
      })
    })
}

export const setFilter = (name, value) => ({
  type: SET_FILTER,
  payload: {
    name,
    value
  }
})
