import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk'
import allReducers from './reducers';

const store = process.env.NODE_ENV === 'development' ? createStore(
  allReducers,
  compose(
    applyMiddleware(thunk),
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  )
) : createStore(
  allReducers,
  applyMiddleware(thunk)
)

export default store