// Libs
import React from 'react'

// Components
import Icon from '../Icon'

const FacebookButton = () => {
  return (
    <a href={process.env.REACT_APP_API_URL + '/connect/facebook'} className="btn facebook"><Icon name="facebook" /> Sign in with Facebook</a>
  )
}

export default FacebookButton
