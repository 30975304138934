import criteriaReducer from './criteriaReducer'
import placeReducer from './placeReducer'
import typePlaceReducer from './typePlaceReducer'
import userReducer from './userReducer'
import { combineReducers } from 'redux'

const allReducers = combineReducers({
  criteriaReducer,
  placeReducer,
  typePlaceReducer,
  userReducer
})

export default allReducers