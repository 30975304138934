// Libs
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'

// Store
import store from '../../store'
import { login, loginWithFacebook } from '../../actions/userActions'

// Components
import Copyrights from '../Copyrights'
import FacebookButton from './FacebookButton'
import LoadingScreen from '../LoadingScreen'

class LoginForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      username: '',
      password: '',
      loading: false
    }
    this.handleChange = this.handleChange.bind(this)
    this.submitForm = this.submitForm.bind(this)
  }
  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    })
  }
  submitForm(e) {
    e.preventDefault()
    this.setState({
      loading: true
    })
    this.props.login(this.state.username, this.state.password)
  }
  componentDidMount() {
    this.unsubscribe = store.subscribe(() => {
      if (store.getState().userReducer.currentUser && localStorage.getItem('jwt')) {
        this.props.history.push('/')
      }
      if (store.getState().userReducer.loginError) {
        this.setState({
          loading: false
        })
      }
    })
  }
  componentWillUnmount() {
    this.unsubscribe()
  }
  render() {
    let error = this.props.loginError ? (
      <div className="error">{this.props.loginError}</div>
    ) : null
    return (
      <div className="content">
        <LoadingScreen
          loading={(this.state.loading)}
        />
        <form className="loginForm" onSubmit={(e) => this.submitForm(e)}>
          <h2 className="big-title">Login</h2>
          {error}
          <input type="email" placeholder="E-mail address" name="username" onChange={this.handleChange} value={this.state.username} />
          <input type="password" placeholder="Password" name="password" onChange={this.handleChange} value={this.state.password} />
          <button type="submit" className="btn">Enter</button>
          <FacebookButton />
          <Link to="/register">Register</Link>
        </form>
        <Copyrights />
      </div>
    )
  }
}

// Prop Types
LoginForm.propTypes = {
  login: PropTypes.func.isRequired,
  loginError: PropTypes.string
}

// Map store state to props
const mapStateToProps = (state) => ({
  loginError: state.userReducer.loginError
})

// Map store actions to props
const mapDispatchToProps = {
  login,
  loginWithFacebook
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LoginForm))
