// Libs
import React, { Component } from 'react'
import PropTypes from 'prop-types'

class ListSuggestions extends Component {
  getListStyle() {
    return {
      opacity: this.props.listSuggestions === false || this.props.listSuggestions.length > 0 ? '1' : '0'
    }
  }
  render() {
    let listSuggestions = Array.isArray(this.props.listSuggestions) && this.props.listSuggestions.length > 0 ? this.props.listSuggestions.map(s => {
      const locationAdress = s.location.address ? s.location.address + (s.location.city ? ', ' + s.location.city : '') : ''
      return (
        <li key={s.id} onClick={() => this.props.addNewPlace(s)}>
          <strong>{s.name}</strong>
          <div>{locationAdress}</div>
        </li>
      )
    }) : typeof this.props.listSuggestions === 'string' ? (
      <li>
        <div>{this.props.listSuggestions}</div>
      </li>
    ) : null
    return (
      <ul className="list-suggestions" style={this.getListStyle()}>
        {listSuggestions}
      </ul>
    )
  }
}

// Prop Types
ListSuggestions.propTypes = {
  listSuggestions: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  addNewPlace: PropTypes.func.isRequired
}

export default ListSuggestions
