// Libs
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

// Components
import Icon from '../Icon'
import BarPin from '../../assets/images/bar-pin.svg'
import CoffeePin from '../../assets/images/coffee-pin.svg'
import RestaurantPin from '../../assets/images/restaurant-pin.svg'
import FavoriteFlag from '../../assets/images/favorite.svg'

function degreesToRadians(degrees) {
  return degrees * Math.PI / 180;
}

function distanceInKmBetweenEarthCoordinates(lat1, lon1, lat2, lon2) {
  var earthRadiusKm = 6371

  var dLat = degreesToRadians(lat2 - lat1)
  var dLon = degreesToRadians(lon2 - lon1)

  lat1 = degreesToRadians(lat1)
  lat2 = degreesToRadians(lat2)

  var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2)
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  return (earthRadiusKm * c).toFixed(1)
}

class PlacesList extends Component {
  constructor(props) {
    super(props)
    this.filteredPlaces = this.filteredPlaces.bind(this)
    this.getPlaceDistance = this.getPlaceDistance.bind(this)
  }
  filteredPlaces = () => {
    const places = this.props.places ? this.props.places.map(p => {
      p.distance = p.latitude && p.longitude ? this.getPlaceDistance(p.latitude, p.longitude) : null
      return p
    }) : null
    return places ? places.filter(p => this.props.placesFilters.indexOf(p.type.name) > -1).sort((a, b) => (a.distance && b.distance ? a.distance - b.distance : -1)) : null
  }
  getPlaceDistance(...coords) {
    return distanceInKmBetweenEarthCoordinates(this.props.currentPosition[0], this.props.currentPosition[1], coords[0], coords[1])
  }
  render() {
    let placesItems = []
    let filteredPlaces = this.filteredPlaces()
    if (filteredPlaces && filteredPlaces.length > 0) {
      let currentCity = ''
      filteredPlaces.map(p => {
        if (currentCity !== p.city) {
          currentCity = p.city
          placesItems.push(<li className="city" key={currentCity}>{currentCity}, {p.country}</li>)
        }
        placesItems.push(
          <li
            className="place"
            onClick={() => this.props.showModalPlace(p)}
            key={p.id}
          >
            <div className="pin">
              {p.favorite ? (
                <img
                  className="favorite-flag"
                  src={FavoriteFlag}
                  alt="Favorite"
                />
              ) : null}
              <img
                src={
                  p.type.name === 'bar'
                    ? BarPin
                    : p.type.name === 'coffee'
                    ? CoffeePin
                    : RestaurantPin
                }
                alt="Pin"
              />
            </div>
            <div className="name">{p.name}</div>
            {p.distance ? (
              <div className="distance">{p.distance} km</div>
            ) : null}
          </li>
        )
        return p
      })
      return (
        <div className="places-list">
          <p>Sorted by: Nearest places</p>
          <ul>{placesItems}</ul>
        </div>
      )
    } else {
      return (
        <div className="places-list no-place">
          <li>
            <Icon name="sad" />
            <p>
              <strong>No place registered for the moment.</strong>
            </p>
            <p>Try to search and add your first place!</p>
          </li>
        </div>
      )
    }
  }
}

// Prop Types
PlacesList.propTypes = {
  currentPosition: PropTypes.array,
  places: PropTypes.array,
  placesFilters: PropTypes.array,
  showModalPlace: PropTypes.func.isRequired
}

// Map store state to props
const mapStateToProps = (state) => ({
  places: state.placeReducer.items,
  placesFilters: state.placeReducer.filters,
  currentPosition: state.placeReducer.currentPosition
})

export default connect(mapStateToProps)(PlacesList)
