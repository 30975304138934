import React from 'react'
import ReactDOM from 'react-dom'
import ReactGA from 'react-ga'
import store from './store'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import App from './App'

import './assets/stylesheets/main.scss'
import 'mapbox-gl/dist/mapbox-gl.css'

if (process.env.NODE_ENV === 'development') {
  ReactGA.initialize('UA-50328591-20')
  ReactGA.pageview(window.location.pathname + window.location.search)
}

require('dotenv').config()
// import * as serviceWorker from './serviceWorker'

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
, document.getElementById('root'))
