// Libs
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

// Store
import { removeErrors } from '../actions/userActions'

// Components
import Logo from '../components/Logo'
import LoginForm from '../components/Auth/LoginForm'

class Login extends Component {
  componentWillUnmount() {
    this.props.removeErrors()
  }
  render() {
    return (
      <div className="App LoginPage">
        <Logo />
        <LoginForm />
      </div>
    )
  }
}

// Prop Types
Login.propTypes = {
  removeErrors: PropTypes.func.isRequired
}

// Map store actions to props
const mapDispatchToProps = {
  removeErrors
}

export default connect(null, mapDispatchToProps)(Login)
