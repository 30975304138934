// Libs
import React from 'react'
import { Link } from 'react-router-dom'

// Components
import Icon from '../Icon'

const Copyrights = () => {
  return (
    <div className="copyrights">
      <p>Made with <Icon name="favorite"><Icon name="canada"></Icon></Icon> in Montreal</p>
      <p><Link to="/privacy">Privacy policy</Link></p>
    </div>
  );
}

export default Copyrights
