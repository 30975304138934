import Api from '../services/Api'
import { FETCH_ALL_CRITERIA } from './index'

const apiService = new Api()

export const fetchAllCriteria = () => dispatch => {
  apiService.fetchAllCriteria()
    .then(res => dispatch({
      type: FETCH_ALL_CRITERIA,
      payload: res
    }))
}
