// Libs
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

// Store
import { switchDisplayMode } from '../../actions/placeActions'

// Components
import Filters from '../Filters'
import Icon from '../Icon'
import Logo from '../Logo'
import ModalSettings from '../Modals/ModalSettings'
import Search from '../Search'

class AppHeader extends Component {
  _isMounted = false
  constructor(props) {
    super(props)
    this.state = {
      showFilters: false,
      showModalSettings: false,
      showActions: false
    }
    this.closeModal = this.closeModal.bind(this)
    this.getActionsClassName = this.getActionsClassName.bind(this)
    this.openCloseActions = this.openCloseActions.bind(this)
    this.openCloseFilters = this.openCloseFilters.bind(this)
    this.openModalSettings = this.openModalSettings.bind(this)
    this.switchDisplayMode = this.switchDisplayMode.bind(this)
  }
  closeModal() {
    if (this._isMounted) {
      this.setState({
        showModalSettings: false
      })
    }
  }
  getActionsClassName() {
    return 'actions' + (this.state.showActions ? ' active' : '')
  }
  openCloseActions() {
    if (this._isMounted) {
      this.setState(state => {
        return {
          showActions: !state.showActions
        }
      })
    }
  }
  openCloseFilters() {
    if (this._isMounted) {
      this.setState(state => {
        return {
          showFilters: !state.showFilters
        }
      })
    }
  }
  openModalSettings() {
    if (this._isMounted) {
      this.setState({
        showModalSettings: true,
        showActions: false,
        showFilters: false
      })
    }
  }
  switchDisplayMode() {
    this.props.switchDisplayMode()
    if (this._isMounted) {
      this.setState({
        showActions: false,
        showFilters: false
      })
    }
  }
  componentDidMount() {
    this._isMounted = true
  }
  componentWillUnmount() {
    this._isMounted = false
  }
  render() {
    const filters = this.state.showFilters ? (<Filters />) : null
    const currentMode = this.props.displayMode === 'list' ? (<Icon name="pin" />) : (<Icon name="list" />)
    const actionsOpen = this.state.showActions ? (<Icon name="cross" />) : null
    return (
      <div className="AppHeader">
        <Logo />
        <div className={this.getActionsClassName()}>
          <button className="star-button" type="button" title="Open actions" onClick={() => this.openCloseActions()}>{actionsOpen}</button>
          <button className="mode-button" type="button" title="Mode" onClick={() => this.switchDisplayMode()}>{currentMode}</button>
          <button className="settings-button" type="button" title="Settings" onClick={() => this.openModalSettings()}><Icon name="settings" /></button>
          <button className="filter-button" type="button" title="Filter" onClick={() => this.openCloseFilters()}><Icon name="filter" /></button>
        </div>
        {filters}
        <Search
          coords={this.props.currentPosition}
          places={this.props.places}
          typesPlaces={this.props.typesPlaces}
          setSelectedPlace={this.setSelectedPlace}
          showModalPlace={this.props.showModalPlace}
        />
        <ModalSettings
          show={this.state.showModalSettings}
          closeModal={this.closeModal}
        />
      </div>
    )
  }
}

// Prop types
AppHeader.propTypes = {
  currentPosition: PropTypes.array.isRequired,
  displayMode: PropTypes.string.isRequired,
  places: PropTypes.array,
  typesPlaces: PropTypes.array,
  showModalPlace: PropTypes.func.isRequired,
  switchDisplayMode: PropTypes.func.isRequired
}

// Map store state to props
const mapStateToProps = (state) => ({
  displayMode: state.placeReducer.displayMode
})

// Map store actions to props
const mapDispatchToProps = {
  switchDisplayMode
}

export default connect(mapStateToProps, mapDispatchToProps)(AppHeader)
