// Libs
import axios from 'axios'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

// Store
import { addPlace, editPlace, removePlace } from '../../actions/placeActions'

// Components
import Icon from '../Icon'
import LoadingScreen from '../LoadingScreen'
import PlaceInfos from '../PlaceInfos'

class ModalPlace extends Component {
  constructor(props) {
    super(props)
    this.state = {
      listValues: [],
      loading: false,
      currentCriteriaId: 0,
      placeInfos: null
    }
    this.addEditPlace = this.addEditPlace.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.filteredList = this.filteredList.bind(this)
    this.getModalClass = this.getModalClass.bind(this)
    this.getModalStyle = this.getModalStyle.bind(this)
    this.getNextButtonClassName = this.getNextButtonClassName.bind(this)
    this.getPrevButtonClassName = this.getPrevButtonClassName.bind(this)
    this.getRadioListClassName = this.getRadioListClassName.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.isChecked = this.isChecked.bind(this)
    this.nextCriteria = this.nextCriteria.bind(this)
    this.prevCriteria = this.prevCriteria.bind(this)
    this.removePlace = this.removePlace.bind(this)
  }
  addEditPlace() {
    this.setState({
      loading: true
    })
    let place = this.props.place
    // We'll remove the address because we don't need it anymore (for the moment, maybe store it later?)
    delete place.address
    place.city = this.state.placeInfos.city
    place.country = this.state.placeInfos.country

    if (!place.id) {
      // Add
      this.props.addPlace(place, this.state.listValues)
    } else {
      // Filter list values that have changed
      const listValuesToEdit = this.state.listValues.filter(lv => {
        const criteriaToEdit = place.placecriteria.filter(pc => pc.criteria === lv.idCriteria)
        // If criteria exist, we'll check if the new value is different / If doesn't exist, we'll create it
        return (criteriaToEdit.length === 1) ? criteriaToEdit[0].value !== lv.value : (criteriaToEdit.length === 0) ? true : false
      })
      // Edit
      if (listValuesToEdit.length > 0) {
        // Edit places infos if there are some values to edit
        this.props.editPlace(place, listValuesToEdit)
      } else {
        // If we have nothing to do, just close the modal
        this.closeModal()
      }
    }
  }
  closeModal() {
    this.setState({
      loading: false,
      listValues: [],
      currentCriteriaId: 0,
      placeInfos: null
    })
    this.props.closeModal()
  }
  filteredList() {
    let placeType = this.props.place ? this.props.place.type : false
    return this.props.listCriteria ? this.props.listCriteria.filter(c => {
      if (c.typeplaces && c.typeplaces.filter(tp => tp.name === placeType.name).length > 0) {
        return true
      }
      return false
    }) : []
  }
  getModalClass() {
    return 'modal modalPlace' + (this.props.place ? ' ' + this.props.place.type.name : '')
  }
  getModalStyle() {
    return {
      transform: this.props.place ? 'translateY(0)' : 'translateY(100%)'
    }
  }
  getNextButtonClassName() {
    return 'btn squared right' + (this.state.currentCriteriaId >= this.filteredList().length - 1 ? ' hidden' : '')
  }
  getPrevButtonClassName() {
    return 'btn squared left' + (this.state.currentCriteriaId <= 0 ? ' hidden' : '')
  }
  getRadioListClassName(id) {
    return 'list-radios' + (id === this.state.currentCriteriaId ? ' active' : '')
  }
  handleChange(event, criteria) {
    let listValues = this.state.listValues.filter(v => v.idCriteria !== criteria.id)
    listValues.push({
      idCriteria: criteria.id,
      value: event.target.value
    })
    this.setState({
      listValues
    })
  }
  isChecked(criteria, value) {
    return this.state.listValues.filter(v => v.idCriteria === criteria.id && v.value === value).length === 1
  }
  nextCriteria() {
    this.setState(state => {
      return {
        currentCriteriaId: ++state.currentCriteriaId
      }
    })
  }
  prevCriteria() {
    this.setState(state => {
      return {
        currentCriteriaId: --state.currentCriteriaId
      }
    })
  }
  removePlace() {
    this.setState({
      loading: true
    })
    this.props.removePlace(this.props.place)
  }
  componentDidUpdate(prevProps) {
    // When a new place is added to our props
    if (this.props.places !== prevProps.places) {
      this.closeModal()
    }
    if (this.props.place && this.props.place !== prevProps.place) {
      this.setState({
        loading: true
      })
      axios.get('https://api.foursquare.com/v2/venues/' + this.props.place.fsPlaceId + '?client_id=' + process.env.REACT_APP_FS_PLACES_CLIENT + '&client_secret=' + process.env.REACT_APP_FS_PLACES_SECRET + '&v=20180323', {
        headers: {
          'Accept-Language': 'en'
        }
      }).then(resFoursquare => {
        const venue = resFoursquare.data.response.venue
        if (venue) {
          const location = venue.location
          this.setState({
            placeInfos: {
              country: (location.country ? location.country : ''),
              city: (location.city ? location.city : ''),
              address: location.address ? location.address + (location.city ? ', ' + location.city : '') + (location.country ? ', ' + location.country : '') : null,
              infos: 'https://www.google.com/search?q=' + encodeURI(venue.name + (location.city ? ', ' + location.city : '')),
              facebook: venue.contact.facebook ? 'https://facebook.com/' + venue.contact.facebook : null,
              instagram: venue.contact.instagram ? 'https://instagram.com/' + venue.contact.instagram : null,
              website: venue.url ? venue.url : null,
              direction: location.address ? 'http://maps.google.com/maps?daddr=' + location.lat + ',' + location.lng : null,
              isOpen: typeof venue.hours !== 'undefined' ? venue.hours.isOpen : null,
              status: venue.hours && venue.hours.status ? venue.hours.status : null
            }
          })
        }
        this.setState({
          loading: false
        })
      })
      if (this.props.place.placecriteria) {
        const listValues = this.props.place.placecriteria.map(pc => {
          return {
            idCriteria: typeof pc.criteria === 'object' && pc.criteria !== null ? pc.criteria.id : pc.criteria,
            value: pc.value
          }
        })
        this.setState({
          listValues
        })
      }
    }
  }
  render() {
    let criteriaEl = this.filteredList().map((c, index) => c.type === 'note' ? (
      <li className={this.getRadioListClassName(index)} key={c.id}>
        <Icon name={'criteria-' + c.slug} />
        <h3>{c.name}</h3>
        <div className="rate">
          <input type="radio" id={'starCriteria' + c.id + '5'} onChange={(e) => this.handleChange(e, c)} checked={this.isChecked(c, '5')} name={'rate' + c.id} value="5" />
          <label htmlFor={'starCriteria' + c.id + '5'} title="text">5 stars</label>
          <input type="radio" id={'starCriteria' + c.id + '4'} onChange={(e) => this.handleChange(e, c)} checked={this.isChecked(c, '4')} name={'rate' + c.id} value="4" />
          <label htmlFor={'starCriteria' + c.id + '4'} title="text">4 stars</label>
          <input type="radio" id={'starCriteria' + c.id + '3'} onChange={(e) => this.handleChange(e, c)} checked={this.isChecked(c, '3')} name={'rate' + c.id} value="3" />
          <label htmlFor={'starCriteria' + c.id + '3'} title="text">3 stars</label>
          <input type="radio" id={'starCriteria' + c.id + '2'} onChange={(e) => this.handleChange(e, c)} checked={this.isChecked(c, '2')} name={'rate' + c.id} value="2" />
          <label htmlFor={'starCriteria' + c.id + '2'} title="text">2 stars</label>
          <input type="radio" id={'starCriteria' + c.id + '1'} onChange={(e) => this.handleChange(e, c)} checked={this.isChecked(c, '1')} name={'rate' + c.id} value="1" />
          <label htmlFor={'starCriteria' + c.id + '1'} title="text">1 star</label>
        </div>
      </li>
    ) : (
      <li className={this.getRadioListClassName(index)} key={c.id}>
        <Icon name={'criteria-' + c.slug} />
        <h3>{c.name}</h3>
        <div className="radio-toggles">
          <div className="radio-true">
            <input type="radio" id={'boolCriteria' + c.id + '1'} onChange={(e) => this.handleChange(e, c)} checked={this.isChecked(c, 'true')} name={'bool' + c.id} value="true" />
            <label htmlFor={'boolCriteria' + c.id + '1'}>Yes</label>
          </div>
          <div className="radio-false">
            <input type="radio" id={'boolCriteria' + c.id + '0'} onChange={(e) => this.handleChange(e, c)} checked={this.isChecked(c, 'false')} name={'bool' + c.id} value="false" />
            <label htmlFor={'boolCriteria' + c.id + '0'}>No</label>
          </div>
        </div>
      </li>
    ))
    const placeInfos = this.state.placeInfos ? (
      <PlaceInfos infos={this.state.placeInfos} />
    ) : null
    return (
      <div>
        <LoadingScreen
          loading={(this.state.loading)}
        />
        <div className={this.getModalClass()} style={this.getModalStyle()}>
          <button className="close-modal-button" onClick={() => this.closeModal()}>x</button>
          <div className="content-modal">
            <Icon name={'pin' + (this.props.place ? '-' + this.props.place.type.name : '')} />
            <h2>{this.props.place ? this.props.place.name : ''}</h2>
            {placeInfos}
            <div className="criteria">
              <button className={this.getPrevButtonClassName()} onClick={() => this.prevCriteria()}><Icon name="arrow-left" /></button>
              <button className={this.getNextButtonClassName()} onClick={() => this.nextCriteria()}><Icon name="arrow-right" /></button>
              <ul>
                {criteriaEl}
              </ul>
            </div>
            <div className="actions">
              <button className="btn" disabled={this.state.loading} onClick={() => this.addEditPlace()}>Done</button>
              <div className="flex-buttons">
                <button className="btn secondary" onClick={() => this.closeModal()}>Cancel</button>
                {this.props.place && this.props.place.id ? (
                  <button className="btn red" onClick={() => this.removePlace()}>Delete</button>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

// Prop Types
ModalPlace.propTypes = {
  addPlace: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  editPlace: PropTypes.func.isRequired,
  listCriteria: PropTypes.array,
  place: PropTypes.object,
  places: PropTypes.array
}

// Map store state to props
const mapStateToProps = (state) => ({
  places: state.placeReducer.items
})

// Map store actions to props
const mapDispatchToProps = {
  addPlace,
  editPlace,
  removePlace
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalPlace)
