// Libs
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import Recaptcha from 'react-recaptcha'
import PropTypes from 'prop-types'

// Store
import store from '../../store'
import { register } from '../../actions/userActions'

// Components
import Copyrights from '../Copyrights'
import FacebookButton from './FacebookButton'
import LoadingScreen from '../LoadingScreen'

// Define global vars
let refRecaptcha

class RegisterForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      username: '',
      email: '',
      password: '',
      confirmPassword: '',
      error: null,
      loading: false
    }
    this.checkFields = this.checkFields.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.verifyCallback = this.verifyCallback.bind(this)
    this.sendForm = this.sendForm.bind(this)
  }
  checkFields() {
    this.setState({
      error: null
    })
    if (!this.state.username || !this.state.email || !this.state.password || !this.state.confirmPassword) {
      this.setState({
        error: 'All fields are required.'
      })
    } else if (this.state.password !== this.state.confirmPassword) {
      this.setState({
        error: 'Passwords are not the same.'
      })
    } else {
      refRecaptcha.execute()
    }
  }
  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value
    })
  }
  verifyCallback(response) {
    if (response) {
      this.setState({
        loading: true
      })
      this.sendForm()
    } else {
      this.setState({
        error: 'Captcha verification failed.'
      })
    }
  }
  sendForm() {
    this.props.register(this.state.username, this.state.email, this.state.password)
  }
  componentDidMount() {
    this.unsubscribe = store.subscribe(() => {
      if (store.getState().userReducer.currentUser && localStorage.getItem('jwt')) {
        this.props.history.push('/')
      }
      if (store.getState().userReducer.registerError) {
        this.setState({
          loading: false
        })
      }
    })
  }
  componentWillUnmount() {
    this.unsubscribe()
  }
  render() {
    let error = this.props.registerError || this.state.error ? (
      <div className="error">{this.props.registerError || this.state.error}</div>
    ) : null
    return (
      <div className="content">
        <LoadingScreen
          loading={(this.state.loading)}
        />
        <form noValidate className="registerForm">
          <h2 className="big-title">Register</h2>
          {error}
          <input type="text" placeholder="Name" name="username" onChange={this.handleChange} value={this.state.username} />
          <input type="email" placeholder="E-mail address" name="email" onChange={this.handleChange} value={this.state.email} />
          <input type="password" placeholder="Password" name="password" onChange={this.handleChange} value={this.state.password} />
          <input type="password" placeholder="Confirm" name="confirmPassword" onChange={this.handleChange} value={this.state.confirmPassword} />
          <button type="button" onClick={this.checkFields} className="btn">Join</button>
          <FacebookButton />
          <Link to="/login">Login</Link>
        </form>
        <Recaptcha
          ref={e => refRecaptcha = e}
          render="explicit"
          size="invisible"
          sitekey="6LfK_7gUAAAAAF39rSYWIOU4q-4UExy8FlPMv-Jr"
          verifyCallback={this.verifyCallback}
        />
        <Copyrights />
      </div>
    )
  }
}

// Prop Types
RegisterForm.propTypes = {
  register: PropTypes.func.isRequired,
  registerError: PropTypes.string
}

// Map store state to props
const mapStateToProps = (state) => ({
  registerError: state.userReducer.registerError
})

// Map store actions to props
const mapDispatchToProps = {
  register
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RegisterForm))
