// Libs
import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

// Store
import { setFilter } from '../../actions/placeActions'

class Filters extends Component {
  constructor(props) {
    super(props)
    this.getFilterClassName = this.getFilterClassName.bind(this)
    this.handleFilters = this.handleFilters.bind(this)
  }
  getFilterClassName(name) {
    return name + (this.props.filters.indexOf(name) > -1 ? ' active' : '')
  }
  handleFilters(e) {
    this.props.setFilter(e.target.name, e.target.checked)
  }
  render() {
    return (
      <div className="filters">
        <label className={this.getFilterClassName('bar')}>
          <input type="checkbox" name="bar" checked={this.props.filters.indexOf('bar') > -1} onChange={e => this.handleFilters(e)} />
          <span>Bar</span>
        </label>
        <label className={this.getFilterClassName('restaurant')}>
          <input type="checkbox" name="restaurant" checked={this.props.filters.indexOf('restaurant') > -1} onChange={e => this.handleFilters(e)} />
          <span>Restaurant</span>
        </label>
        <label className={this.getFilterClassName('coffee')}>
          <input type="checkbox" name="coffee" checked={this.props.filters.indexOf('coffee') > -1} onChange={e => this.handleFilters(e)} />
          <span>Coffee</span>
        </label>
      </div>
    )
  }
}

// Prop Types
Filters.propTypes = {
  filters: PropTypes.array,
  setFilter: PropTypes.func.isRequired
}

// Map store state to props
const mapStateToProps = (state) => ({
  filters: state.placeReducer.filters
})

// Map store actions to props
const mapDispatchToProps = {
  setFilter
}

export default connect(mapStateToProps, mapDispatchToProps)(Filters)
