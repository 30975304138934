// Libs
import React from 'react'
import PropTypes from 'prop-types'

const nbPathsIcons = {
  'criteria-deco': 4,
  'criteria-vibes': 19,
  'criteria-quality': 16,
  'criteria-price': 11,
  'criteria-transports': 8,
  'criteria-speed': 8,
  'criteria-nice': 11,
  'criteria-size': 10,
  'criteria-noise': 4,
  'criteria-byow': 22,
  'criteria-cards': 5,
  'pin-bar': 4,
  'pin-coffee': 5,
  'pin-restaurant': 3,
  'sad': 6
}

const Icon = (props) => {
  const { children, name } = props
  const iconClass = 'icon-' + name
  const paths = []
  if (nbPathsIcons[name]) {
    for (var i = 1; i <= nbPathsIcons[name]; i++) {
      paths.push(<span key={i} className={'path' + i}></span>)
    }
  }
  return (<i className={iconClass}>{paths}{children}</i>)
}

// Prop Types
Icon.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
}

export default Icon
